import React from "react"
import { graphql } from "gatsby"
import Layout from "components/Layout"
import SEO from "components/SEO"
import SliceZone from "components/SliceZone"
import { linkResolver } from "utils/linkResolver"

function Page({
  data: {
    prismic: { page, site_settings },
  },
  ...props
}) {
  if (!page) return null
  const { body, _meta, ...meta } = page
  const uri = linkResolver(_meta)
  return (
    <Layout site_settings={site_settings}>
      <SEO
        slug={uri}
        title={meta.meta_title}
        description={meta.meta_description}
        images={meta.meta_images}
      />
      <SliceZone slices={body} />
    </Layout>
  )
}

export default Page

Page.defaultProps = {}

Page.propTypes = {}

export const query = graphql`
  query Page($lang: String!, $uid: String!, $isProduction: Boolean!) {
    prismic {
      page(lang: $lang, uid: $uid) {
        _meta {
          uid
          type
          lang
        }
        meta_title
        meta_description
        meta_images {
          image
          imageSharp @include(if: $isProduction) {
            childImageSharp {
              id
              main: fixed(quality: 80, width: 1200) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
        body {
          ... on PRISMIC_PageBodyTiles {
            type
            label
            primary {
              tiles_brand_element {
                ... on PRISMIC_Brand_element_group {
                  ...BrandElementsGroup
                }
              }
              tiles_brand_element_position
            }
            fields {
              tile {
                ... on PRISMIC_Tile {
                  preheader
                  header
                  button_text
                  image
                  imageSharp @include(if: $isProduction) {
                    childImageSharp {
                      id
                      main: fixed(quality: 80, width: 1280) {
                        ...GatsbyImageSharpFixed
                      }
                      mobile: fixed(quality: 80, width: 480) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                  summary
                  link {
                    ... on PRISMIC_Article {
                      _meta {
                        uid
                        type
                        lang
                      }
                    }
                  }
                }
              }
              tile_theme
            }
          }
          ... on PRISMIC_PageBodyModule {
            type
            label
            primary {
              module {
                ... on PRISMIC_Instructions {
                  _meta {
                    type
                  }
                  steps {
                    after_icon
                    before_icon
                    step_icon
                  }
                }
              }
            }
          }
          ... on PRISMIC_PageBodyCountdown {
            type
            label
            primary {
              countdown {
                ... on PRISMIC_Countdown {
                  ...CountdownFragment
                }
              }
              display_form
              display_greeting
              display_content
            }
          }
          ... on PRISMIC_PageBodyParagraph {
            type
            label
            primary {
              title
              subtitle
              content
            }
          }
          ... on PRISMIC_PageBodyParagraph___image {
            type
            label
            primary {
              image_position
              content
              image: paragraph_image
              imageSharp: paragraph_imageSharp @include(if: $isProduction) {
                childImageSharp {
                  id
                  desktop: fixed(quality: 80, width: 600) {
                    ...GatsbyImageSharpFixed
                  }
                  mobile: fixed(quality: 80, width: 400) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
          ... on PRISMIC_PageBodyRanking {
            type
            label
            primary {
              ranking {
                ... on PRISMIC_Ranking {
                  title
                  type
                  from
                  to
                }
              }
            }
          }
          ... on PRISMIC_PageBodyBrand_elements {
            type
            fields {
              brand_element_group_item {
                ... on PRISMIC_Brand_element_group {
                  ...BrandElementsGroup
                }
              }
            }
          }
          ... on PRISMIC_PageBodyCta {
            type
            primary {
              cta_content
              cta_brand_element_position
              cta_brand_element {
                ... on PRISMIC_Brand_element_group {
                  ...BrandElementsGroup
                }
              }
            }
          }
          ... on PRISMIC_PageBodyBlog_posts {
            type
          }
        }
      }
      site_settings(lang: $lang, uid: "site-settings") {
        ...Footer
      }
    }
  }
`
